export const multitenancyColorMapper = [
  {
    hostname: process.env.REACT_APP_INVESTINET_STAGE,
    customWhite01: "#EDF4FD",
    customBlue: "#639FF1",
  },
  {
    hostname: process.env.REACT_APP_INVESTINET_PROD,
    customWhite01: "#EDF4FD",
    customBlue: "#639FF1",
  },
  {
    hostname: process.env.REACT_APP_CONSUEGRA_STAGE,
    customWhite01: "#cfe3f9",
    customBlue: "#639FF1",
  },
  {
    hostname: process.env.REACT_APP_CONSUEGRA_PROD,
    customWhite01: "#cfe3f9",
    customBlue: "#639FF1",
  },
  {
    hostname: process.env.REACT_APP_DEMO_STAGE,
    customWhite01: "#EBEAFC",
    customBlue: "#25149C",
  },
  {
    hostname: process.env.REACT_APP_DEMO_PROD,
    customWhite01: "#EBEAFC",
    customBlue: "#25149C",
  },
  {
    hostname: process.env.REACT_APP_TROMBERG_STAGE,
    customWhite01: "#EBEAFC",
    customBlue: "#002856",
  },
  {
    hostname: process.env.REACT_APP_TROMBERG_PROD,
    customWhite01: "#EBEAFC",
    customBlue: "#002856",
  },
  {
    hostname: process.env.REACT_APP_NCLS_STAGE,
    customWhite01: "#DFFFDB",
    customBlue: "#62945A",
  },
  {
    hostname: process.env.REACT_APP_NCLS_PROD,
    customWhite01: "#DFFFDB",
    customBlue: "#62945A",
  },
  {
    hostname: process.env.REACT_APP_TIDEWATER_STAGE,
    customWhite01: "#D4EAFF",
    customBlue: "#003871",
  },
  {
    hostname: process.env.REACT_APP_TIDEWATER_PROD,
    customWhite01: "#D4EAFF",
    customBlue: "#003871",
  },
];
