import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useAlert } from "react-alert";
import { useTranslation } from 'react-i18next';
import Checkbox from "@material-ui/core/Checkbox";
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import { ConsumerFooter } from "../../ConsumerFooter";
import { getShortConsumerDebts_new } from "lib/api/common";
import { LoadingSpinner } from "components/LoadingSpinner";
import { multitenancyPrivacy, multitenancyEsign } from "multitenancy/multitenancyContactInfo";
import { CustomButton } from "components/ui-kit/CustomButtom";

import { SuccessHelpModal } from "../SuccessHelpModal";
import { fetchConsumerProfile } from "redux/actions/profile";

import { createDispute } from "lib/api/common";

import "../style.scss";
import { wtError } from "lib/helpers/dataLayers";

export const ComplainsPage = () => {

  const dispatch = useDispatch();
  const alert = useAlert();

  const [formedSelectFilter, setFormSelectFilter] = useState([]);
  const [selectedSearchDataId, setSelectedSearchDataId] = useState();
  const [selectedRefNumber, setSelectedRefNumber] = useState();
  const [customerName, setCustomerName] = useState("");
  const [loading, setLoading] = useState(true);
  const [newComplaintNoteText, setComplaintNoteText] = useState("");
  const [newOutcomeNoteText, setOutcomeNewNoteText] = useState("");
  const [acceptTermsCond, setAcceptTermsCond] = useState(false);
  const [sendTimeout, setSendTimeout] = useState(true);

  const [successHelpModal, setSuccessHelpModal] = useState(false);
  const toggleSuccessHelpModal = () => setSuccessHelpModal(!successHelpModal);

  const consumersData = useSelector((state) => state.profile.profileData);

  const { t } = useTranslation();

  useEffect(() => {
    fetchDebtInfo();
        // fetch profile info to redux
        _.isEmpty(consumersData) && dispatch(fetchConsumerProfile());
        setCustomerName(consumersData?.profile?.full_name);
  }, [consumersData])

  const fetchDebtInfo = async() => {
    const response = await getShortConsumerDebts_new({
      statuses: ["active", "established", "recalled", "paid_off", "delinquent", "on_hold"],
    });
    if (response.success === "True") {
      formingSelectFilter(response.data);
    }
  }

  const formingSelectFilter = (data) => {
    let formedArray = [];
    data.map((item) => {
      const object = {
        label: item.referenceNumber,
        value: item.referenceNumber
      }
      formedArray.push(object);
    })
    setFormSelectFilter(formedArray);
    setSelectedSearchDataId(formedArray[0]);
    setSelectedRefNumber(formedArray[0].value);
    setLoading(false);
  }

  const filterByDataId = (e) => {
    setSelectedSearchDataId(e);
    setSelectedRefNumber(e.value);
  }

  const changeName = (e) => {
    setCustomerName(e.target.value)
  }

  const toggleTerms = (e) => {
    setAcceptTermsCond(e.target.checked);
  }

  const sendRequest = async() => {
    setSendTimeout(false);
    setTimeout(() => setSendTimeout(true), 3000);

    const request = {
      data_id: selectedRefNumber,
      dispute_type: "complaint",
      name: customerName,
      explanation: newComplaintNoteText,
      expected_outcome: newOutcomeNoteText,
      reasons: ""
    }

    setLoading(true);
    const data = await createDispute(request);
    if (data.success === "True") {
      setLoading(false);
      toggleSuccessHelpModal();
    } else {
      setLoading(false);
      wtError(data);
      alert.error(data.explanation[0]);
    }
  }

  const handleKeyDown = (e) => {
    var start = e.target.selectionStart;
    var end = e.target.selectionEnd;

    if (e.which === 9) {
      e.preventDefault();
      e.target.value = e.target.value.substring(0, start) + '\t' + e.target.value.substring(end);
      e.target.selectionStart = e.target.selectionEnd = start + 1;
    }
    
  }

  return (
    <>
      <Helmet>
        <title>{t("Complaints")}</title>
      </Helmet>
      <div className="help-center-body">
        <div className="title">{t("Complaints")}</div>
        <div className="subtitle">
          <p>{t("Welcome. Your feedback is essential to us. If you are dissatisfied with our services or would like to express your concerns, we would like the opportunity to improve your experience.")}</p>
          <p>{t("Once we have received your request, we will review your complaint and reach out to you if we need additional information.")}</p>
          <p>{t("By checking “I accept the terms of the")} {multitenancyPrivacy()} {t("and")} {multitenancyEsign()} {t("policy“, you agree to our data handling policies and affirm the information you have provided is true.")}</p>
          <p>{t("We're here to learn and improve, so your feedback is highly appreciated. Thank you for helping us serve you better.")}</p>
        </div>
        {loading ? (
          <LoadingSpinner middleFixed={true}/>
        ) : (
          <div className="input-containers">
            <div className="header-container">
              <div className="name-container">
                <span>{t("Name")}</span>
                <input
                  className="name-input"
                  type="text"
                  placeholder={t("Name")}
                  onChange={changeName}
                  value={customerName || ""}
                />
              </div>
              <div className="name-container">
                <span>{t("Reference Number")}</span>
                <Select
                  className="select-container"
                  id="search-dropdown"
                  placeholder={t("Reference number")}
                  defaultValue={selectedSearchDataId}
                  options={formedSelectFilter}
                  onChange={filterByDataId}
                />
              </div>
            </div>
            <div className="notes-container">
              <span>{t("Explain your complaint with as much detail as possible")}</span>
              <textarea
                onChange={(e) => setComplaintNoteText(e.target.value)}
                onKeyDown={handleKeyDown}
                value={newComplaintNoteText}
                className="notes-input small"
                name="comment"
                id="comment"
                placeholder={t("Type text here")}
              ></textarea>
            </div>
            <div className="notes-container">
              <span>{t("What outcome would you like to achieve?")}</span>
              <textarea
                onChange={(e) => setOutcomeNewNoteText(e.target.value)}
                onKeyDown={handleKeyDown}
                value={newOutcomeNoteText}
                className="notes-input small"
                name="comment"
                id="comment"
                placeholder={t("Type text here")}
              ></textarea>
            </div>
          </div>
        )}
        <div className="terms-container">
          <Checkbox
            className="checkbox-value"
            checked={acceptTermsCond}
            onChange={toggleTerms}
            name="terms"
            size="small"
          />
          <span className="text-cont">{t("I accept the terms of the")} {multitenancyPrivacy()} {t("and")} {multitenancyEsign()}</span>
        </div>
        <CustomButton
          text={t("Send")}
          onClickHandler={() => sendRequest()}
          optionalClass="send-btn"
          disabled={!acceptTermsCond || !sendTimeout || newOutcomeNoteText.length < 1 || newComplaintNoteText.length < 1}
        />
      </div>
      <ConsumerFooter />
      {successHelpModal && (
        <SuccessHelpModal
          modal={successHelpModal}
          type="complaints"
        />
      )}
    </>
  )
}