
let hostname = window.location.hostname.toLowerCase();
const appPort = process.env.REACT_APP_BACKEND_PORT;
const appApi = process.env.REACT_APP_BACKEND_API;
const appPrefix = process.env.REACT_APP_BACKEND_API_PREFIX;
const appProtocol = process.env.REACT_APP_BACKEND_API_PROTOCOL;

// for Redesign url call defauld api
if (hostname === process.env.REACT_APP_DEMO_STAGE) {
  hostname = process.env.REACT_APP_INVESTINET_STAGE;
}

// remove after API domain changed
if (hostname === process.env.REACT_APP_INVESTINET_PROD) {
  hostname = "waythru.investinet.com";
}

const formatUrl = (body) => {
  let queryString = Object.keys(body).map(key => key + '=' + body[key]).join('&');
  return queryString;
}

export default async function sendRequest(path, method = "GET", body) {
  const bearer = JSON.parse(localStorage.getItem("userData"))
    ? JSON.parse(localStorage.getItem("userData")).token?.toString()
    : "";

  const Authorization = bearer && bearer !== "" ? `Bearer ${bearer}` : "";

  if (method === "GET" && body) {
    const response = await fetch(
      `${appProtocol}://${appPrefix}${hostname}${appPort}${appApi}${path}?` + formatUrl(body),
      {
        method: method,
        mode: "cors",
        headers: new Headers({
          Authorization,
          "Content-Type": "application/json",
        }),
        // body: JSON.stringify(body),
      }
    );
    // check for 500 error 
    if (response.status === 500) {
      return response;
    }
    const data = await response.json();
    if (data.error) {
      throw new Error(data.error);
    }

    return data;

  } else {
    const response = await fetch(
      `${appProtocol}://${appPrefix}${hostname}${appPort}${appApi}${path}`,
      {
        method: method,
        mode: "cors",
        headers: new Headers({
          Authorization,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(body),
      }
    );
    // check for 500 error 
    if (response.status === 500) {
      return response;
    }
    const data = await response.json();
    if (data.error) {
      throw new Error(data.error);
    }

    return data;
  }
}
