import React from "react";
import "./style.scss";

import WaythruLogo from "scss/images/logo.png";
import ConsuegraLogo from "scss/images/consuegra-logo.png";
import WayLogo from "scss/images/logo_W.svg";
import TrombergLogi from "scss/images/TMP_Logo.png";
import nclsLogo from "scss/images/ncls-logo.png";
import tidewaterLogo from "scss/images/tidewater-logo.png";



export const MultitenancyLogoPicker = () => {

  const hostname = window.location.hostname.toLowerCase();

  const getLogo = () => {
    switch (hostname) {
      case process.env.REACT_APP_INVESTINET_STAGE:
        return <img className="logo-img" src={WaythruLogo} alt="logo" />;
      case process.env.REACT_APP_INVESTINET_PROD:
        return <img className="logo-img" src={WaythruLogo} alt="logo" />;
      case process.env.REACT_APP_CONSUEGRA_STAGE:
        return <img className="logo-img" src={ConsuegraLogo} alt="logo" />;
      case process.env.REACT_APP_CONSUEGRA_PROD:
        return <img className="logo-img" src={ConsuegraLogo} alt="logo" />;
      case process.env.REACT_APP_DEMO_STAGE:
        return <img className="logo-img" src={WayLogo} alt="logo" />;
      case process.env.REACT_APP_DEMO_PROD:
        return <img className="logo-img" src={WayLogo} alt="logo" />;
      case process.env.REACT_APP_TROMBERG_STAGE:
        return <img className="logo-img" src={TrombergLogi} alt="logo" />;
      case process.env.REACT_APP_TROMBERG_PROD:
        return <img className="logo-img" src={TrombergLogi} alt="logo" />;
      case process.env.REACT_APP_NCLS_STAGE:
        return <img className="logo-img" src={nclsLogo} alt="logo" />;
      case process.env.REACT_APP_NCLS_PROD:
        return <img className="logo-img" src={nclsLogo} alt="logo" />;
      case process.env.REACT_APP_TIDEWATER_STAGE:
        return <img className="logo-img" src={tidewaterLogo} alt="logo" />;
      case process.env.REACT_APP_TIDEWATER_PROD:
        return <img className="logo-img" src={tidewaterLogo} alt="logo" />;
    }
  }


  return (
    <>
      {getLogo()}
    </>
  )
}