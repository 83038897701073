import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { TopNavigation } from "./components/TopNavigation";

import { AdminLogin } from "./pages/AdminLogin";
import ProfilePage from "./pages/ProfilePage";

import { SettingsPage } from "./pages/SettingsPage";
import { SetupPlan } from "./pages/SetupPlan";
import { Consumers } from "./pages/Consumers";
import { Agents } from "./pages/Agents";
import SingleConsumer from "./pages/Consumers/components/SingleConsumer";
import SingleAgent from "./pages/Agents/components/SingleAgent";
import { AddNewAgent } from "./pages/Agents/components/AddNewAgent";

import { Page404 } from "./pages/Page404";
import { ChangePaymentMethod } from "./pages/ChangePaymentMethod";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { InventoryPage } from "./pages/InventoryPage";
import { ChangePasswordPage } from "./pages/ChangePasswordPage";
import { DemandLetters } from "./pages/DemandLetters";
import { DisputesAdminPage } from "./pages/DisputesAdminPage";

import { EmailTemplatesPage } from "NewAdmin/EmailTemplatesPage";

// import { TermsAndConditions } from "pages/TermsAndConditions";
// import { PrivacyPolicy } from "pages/PrivacyPolicy";
import { SuccessSignedPage } from "ConsumerPage/components/DocuSignIntegration/SuccessEsignPage";
import { PostMessageSuccessPage } from "pages/PostMessageSuccessPage";
import { HelpCenterPage } from "ConsumerPage/HelpCenterPage";
import { HardshipsPage } from "ConsumerPage/HelpCenterPage/HardshipsPage";
import { DisputesPage } from "ConsumerPage/HelpCenterPage/DisputesPage";
import { ComplainsPage } from "ConsumerPage/HelpCenterPage/ComplainsPage";
import { UnsubscribePage } from "ConsumerPage/UnsubscribePage";
import { EsignPage } from "ConsumerPage/EsignPage";

const TestLoginDefault = React.lazy(() => import('./pages/Test-login-1'));
const TestLoginAccess = React.lazy(() => import('./pages/Test-login-2'));
const ConsumerPage = React.lazy(() => import('ConsumerPage'));
const OnboardingPage = React.lazy(() => import('ConsumerPage/Onboarding'));
const ProfileInfoPage = React.lazy(() => import('ConsumerPage/ProfileInfoPage'));
const ConsumerHeader = React.lazy(() => import('ConsumerPage/ConsumerHeader'));

export const useRoutes = (isAuthenticated, role) => {

  if (isAuthenticated && role === "user") {

    return (
      <Switch>
        <Route path="/payment-success" exact>
          <PostMessageSuccessPage/>
        </Route>
        <Route path="/signed-success" exact>
          <SuccessSignedPage/>
        </Route>
        <Route path="/onboarding">
          <Suspense fallback={<></>}>              
            <ConsumerHeader page="/onboarding"/>
            <OnboardingPage />
          </Suspense>
        </Route>
        <Route path="/cabinet-active">
          <Suspense fallback={<></>}>
            <ConsumerHeader page="/cabinet-active"/>
            <ConsumerPage route="/cabinet-active"/>
          </Suspense>
        </Route>
        <Route path="/cabinet-archive">
          <Suspense fallback={<></>}>
            <ConsumerHeader page="/cabinet-archive"/>
            <ConsumerPage route="/cabinet-archive"/>
          </Suspense>
        </Route>
        <Route path="/profile-info">
          <Suspense fallback={<></>}>              
            <ConsumerHeader page="/profile-info"/>
            <ProfileInfoPage />
          </Suspense>
        </Route>

        <Route path="/help-center" exact>
          <Suspense fallback={<></>}>              
            <ConsumerHeader page="/help-center"/>
            <HelpCenterPage />
          </Suspense>
        </Route>
        <Route path="/help-center/hardships" exact>
          <Suspense fallback={<></>}>              
            <ConsumerHeader page="/help-center"/>
            <HardshipsPage />
          </Suspense>
        </Route>
        <Route path="/help-center/disputes" exact>
          <Suspense fallback={<></>}>              
            <ConsumerHeader page="/help-center"/>
            <DisputesPage />
          </Suspense>
        </Route>
        <Route path="/help-center/complaints" exact>
          <Suspense fallback={<></>}>              
            <ConsumerHeader page="/help-center"/>
            <ComplainsPage />
          </Suspense>
        </Route>

        <Route path="/unsubscribe" exact>
          <Suspense fallback={<></>}>
            <ConsumerHeader page="/unsubscribe"/>
            <UnsubscribePage />
          </Suspense>
        </Route>

        <Route path="/electronic-signature-authorization" exact>
          <Suspense fallback={<></>}>
            <ConsumerHeader page="/electronic-signature-authorization"/>
            <EsignPage />
          </Suspense>
        </Route>

        {/* <Route path="/privacy" exact>
          <PrivacyPolicy />
        </Route>

        <Route path="/terms" exact>
          <TermsAndConditions />
        </Route> */}

        <Redirect from="/" to="/cabinet-active" />
        <Route path="*">
          <div className="no-page">404</div>
        </Route>
      </Switch>
    );
  } else if (
    (isAuthenticated && role === "admin") ||
    (isAuthenticated && role === "agent")
  ) {
    // start DOTO: Important to remove this after remove chatbot
    const chatbotScript = document.getElementById("chatbot-script");
    const chatbotInit = document.getElementById("chatbot-init");
    const chatbotContainer = document.getElementById("front-chat-container");
    const frontChatIframe = document.getElementById("front-chat-iframe");
    if (chatbotScript) {
      chatbotScript.remove();
    }
    if (chatbotInit) {
      chatbotInit.remove();
    }
    if (chatbotContainer) {
      chatbotContainer.style.display = "none"
    }
    if (frontChatIframe){
      frontChatIframe.style.display = "none";
    }
    // end TODO
    return (
      <Switch>
        <Route path="/payment-success" exact>
          <PostMessageSuccessPage/>
        </Route>
        <Route path="/signed-success" exact>
          <SuccessSignedPage/>
        </Route>
        <Route path="/admin/consumers" exact>
          <>
            <TopNavigation isAdminAuthenticated={role} />
            <Consumers />
          </>
        </Route>
        <Route path="/admin/consumers/consumer/:id" exact>
          <>
            <TopNavigation isAdminAuthenticated={role} />
            <SingleConsumer />
          </>
        </Route>
        <Route path="/admin/consumers/consumer/:id/email-templates">
          <>
            <TopNavigation isAdminAuthenticated={role}/>
            <EmailTemplatesPage />
          </>
        </Route>
        <Route
          path="/admin/consumers/consumer/change-payment-method/:debtId/:id"
          exact
        >
          <>
            <TopNavigation isAdminAuthenticated={role} />
            <ChangePaymentMethod />
          </>
        </Route>
        <Route path="/admin/roles" exact>
          <>
            <TopNavigation isAdminAuthenticated={role} />
            <Agents />
          </>
        </Route>
        <Route path="/admin/agents/agent/:id">
          <>
            <TopNavigation isAdminAuthenticated={role} />
            <SingleAgent />
          </>
        </Route>
        <Route path="/admin/new-agent">
          <>
            <TopNavigation isAdminAuthenticated={role} />
            <AddNewAgent />
          </>
        </Route>
        <Route path="/admin/profile" exact>
          <>
            <TopNavigation isAdminAuthenticated={role} />
            <ProfilePage />
          </>
        </Route>
        <Route path="/admin/change-password" exact>
          <>
            <TopNavigation isAdminAuthenticated={role} />
            <ChangePasswordPage />
          </>
        </Route>
        <Route path="/setup-plan/:debtId/:planId/:id" exact>
          <>
            <TopNavigation isAdminAuthenticated={role} />
            <SetupPlan />
          </>
        </Route>
        <Route path="/setup-otp/:debtId/:id" exact>
          <>
            <TopNavigation isAdminAuthenticated={role} />
            <SetupPlan />
          </>
        </Route>
        <Route path="/admin/settings">
          <>
            <TopNavigation isAdminAuthenticated={role} />
            <SettingsPage />
          </>
        </Route>
        <Route path="/admin/inventory">
          <>
            <TopNavigation isAdminAuthenticated={role} />
            <InventoryPage />
          </>
        </Route>
        <Route path="/admin/letters">
          <>
            <TopNavigation isAdminAuthenticated={role} />
            <DemandLetters />
          </>
        </Route>
        <Route path="/admin/disputes">
          <TopNavigation isAdminAuthenticated={role} />
          <DisputesAdminPage />
        </Route>
        <Redirect from="/" to="/admin/consumers" />
        <Route path="*">
          <div className="no-page">404</div>
        </Route>
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route path="/payment-success" exact>
          <PostMessageSuccessPage/>
        </Route>
        <Route path="/signed-success" exact>
          <SuccessSignedPage/>
        </Route>
        <Route path="/unsubscribe" exact>
          <Suspense fallback={<></>}>
            <ConsumerHeader page="/unsubscribe"/>
            <UnsubscribePage page="/unsubscribe"/>
          </Suspense>
        </Route>

        <Route path="/electronic-signature-authorization" exact>
          <Suspense fallback={<></>}>
            <ConsumerHeader page="/electronic-signature-authorization"/>
            <EsignPage page="/electronic-signature-authorization"/>
          </Suspense>
        </Route>

        <Route path="/" exact>
          <Suspense fallback={<></>}>
            <TestLoginDefault />
          </Suspense>
        </Route>
        <Redirect from="/login-1" to="/" />
        <Route path="/login-2" exact>
          <Suspense fallback={<></>}>
            <TestLoginAccess />
          </Suspense>
        </Route>

        <Route path="/admin" exact>
          <AdminLogin />
        </Route>

        {/* <Route path="/privacy" exact>
          <PrivacyPolicy />
        </Route>

        <Route path="/terms" exact>
          <TermsAndConditions />
        </Route> */}

        <Route path="/forgot-password" exact>
          <ForgotPasswordPage />
        </Route>

        <Route path="*">
          <div className="no-page">404</div>
          <Page404 />
        </Route>

      </Switch>
    );
  }
};
