import React, { useEffect, useState } from "react";

import GoDaddyLogo from "./images/godaddy.png";
import EntrustLogo from "./images/entrust.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { MultitenancyLogoPicker } from "multitenancy/multitenancyLogoPicker";
import { multitenancyTerms, multitenancyPrivacy } from "multitenancy/multitenancyContactInfo";

import { useTranslation } from "react-i18next";
import { updateProfile } from "redux/actions/profile";

import ReactFlagsSelect from "react-flags-select";

import "./style.scss";

export const ConsumerFooter = ({page}) => {

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const consumerLanguage = useSelector((state) => state.profile.profileData?.profile?.language);
  const [siteLanguage, setSiteLanguage] = useState("US"); 

  useEffect(() => {
    setSiteLanguage(languageToCode(consumerLanguage));
    i18n.changeLanguage(languageToCode(consumerLanguage));
  }, [consumerLanguage])

  const languageToCodeMap = {
    "English": "US",
    "Español": "ES"
  };
  const codeToLanguageMap = {
    "US": "English",
    "ES": "Español"
  };
  const languageToCode = (language) => languageToCodeMap[language] || "US";
  const codeToLanguage = (code) => codeToLanguageMap[code] || "English";

  const handleChangeLanguage = (code) => {
    dispatch(updateProfile({language: codeToLanguage(code)}));
    i18n.changeLanguage(code);
  }
  
  return (
    <div className="consumer-footer">
      <div className="top-blocks">
        <div className="links-container">
          <div className="logo">
            <MultitenancyLogoPicker />
          </div>
          <div className="links">
            { window.location.hostname.toLowerCase() === process.env.REACT_APP_INVESTINET_PROD || 
              window.location.hostname.toLowerCase() === process.env.REACT_APP_INVESTINET_STAGE && (
                <a className="heading heading-caption-bold"
                  href="https://www.nmlsconsumeraccess.org/"
                  target="_blank" 
                  rel="noopener noreferrer" 
                >
                  NMLS ID: 937604
                </a>
              )
            }
            {multitenancyTerms()}
            {multitenancyPrivacy()}
            {page !== "/unsubscribe" && (
              <a href="/help-center" className="heading">{t("Help Center")}</a>
            )}
          </div>
        </div>
        <div className="security-images">
          <img className="go-daddy" src={GoDaddyLogo} alt="GoDaddyLogo logo" />
          <img className="entrust" src={EntrustLogo} alt="EntrustLogo logo" />
        </div>
      </div>
      <div className="bottom-blocks">
        <div className="lang-cont">
          <ReactFlagsSelect
            className="language-dropdown"
            selected={siteLanguage}
            onSelect={handleChangeLanguage}
            countries={["US", "ES"]}
            customLabels={{"US": "ENG","ES": "ESP"}}
            placeholder="Select"
          />
          <span className="info">
            {t("This communication is from a debt collector and is an attempt to collect a debt. Any information obtained will be used for that purpose.")}
          </span>
        </div>
        <span className="copyright">{t("© All rights reserved,")} {moment(Date()).format("YYYY")}</span>
      </div>
    </div>
  )
}