import { useState } from "react";
import moment from "moment";
import { getNACHA, getReceiptTemplate } from "../../../lib/api/common";
import { convertReceiptData } from "../helpers";

export function useModal(id, debt, alert) {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [NACHAModalData, setNACHAModalData] = useState({
    payment_frequency: "Monthly",
    per_month: "0",
    start_date: moment(Date()).format("ll"),
    type: "1",
    bank_name: "",
    name_on_check: "",
    routing_number: "",
    account_number: "",
    payment_amount: "0",
  });

  const [selectedHistoryType, setSelectedHistoryType] = useState(null);

  const [modalDate, sertModalDate] = useState("");
  const [selectedPayer, setSelectedPayer] = useState(null);

  const handleGetReceipt = async (amount, date, type, is_open_ended) => {
    window.scrollTo(0, 0);
    const response = await getReceiptTemplate({
      client_id: id,
      debt_id: debt.debt_id,
      pay_amount: amount,
      pay_date: date,
      transaction_type: type,
      data_id: debt.referenceNumber,
      is_open_ended: is_open_ended
    });

    if (response.success === "True") {
      sertModalDate(date);
      setModalData(
        convertReceiptData(response.receipt_template)
      );
      document.body.style.overflow = "hidden";
      setShowModal(true);
    }
  };

  const handleShowNACHAModal = async (item) => {
    const nachaData = await getNACHA({
      client_id: id,
      payment_date: item.payment_date,
      payment_frequency: item.payment_frequency,
      data_id: debt.referenceNumber,
      debt_id: debt.debt_id,
    });

    if (nachaData.success !== "True") {
      alert.error(`Error: ${nachaData.message}`);
      return;
    }
    setSelectedPayer(
      nachaData.nacha_details.initiator === 0 ? "admin/agent" : "consumer"
    );
    setSelectedHistoryType(item.payment_frequency);
    setNACHAModalData((prevData) => ({
      ...prevData,
      per_month: item.amount,
      account_number: nachaData.nacha_details.account_number,
      routing_number: nachaData.nacha_details.routing_number,
      type: nachaData.nacha_details.account_type,
      name_on_check: nachaData.nacha_details.name_on_check,
      payment_amount: item.amount,
      bank_name: nachaData.nacha_details.bank_name,
      end_date: nachaData.nacha_details.end_date,
      end_amount: nachaData.nacha_details.end_amount,
      start_date: nachaData.nacha_details.start_date,
      start_amount: nachaData.nacha_details.start_amount,
      number_of_payments: nachaData.nacha_details.number_of_payments,
      payment_frequency: nachaData.nacha_details.periodicity,
      agreement_timestamp: nachaData.nacha_details.agreement_timestamp
    }));
  };

  return {
    modalData,
    NACHAModalData,
    selectedHistoryType,
    setSelectedHistoryType,
    modalDate,
    selectedPayer,
    showModal,
    setShowModal,
    handleGetReceipt,
    handleShowNACHAModal,
  };
}
