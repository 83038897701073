import React, { useEffect } from "react";

import alertIcon from "scss/images/alert.png";
import { multitenancyPhone, multitenancyTelUrl } from "multitenancy/multitenancyContactInfo";

import { 
  FailedPaymentDataLayer,
} from "lib/helpers/dataLayers";

import { useTranslation } from 'react-i18next';

import { asaStatuses } from "lib/helpers/constants";

import "./style.scss";

export const FailedPaymentInfo = ({errorMessage, alert_notification, asa_status}) => {

  const { t } = useTranslation();

  useEffect(() => {
    FailedPaymentDataLayer();
  },[])
  return (
    <div className="failed-payment-info-container">
      <div className="text-label">
        <img src={alertIcon} alt="alert"/>
        {asa_status === asaStatuses.DECLINED_BY_ATTORNEY && (
          <div>{t("Your agreement was not approved by the attorney. For assistance, please contact us at ")} <a href={multitenancyTelUrl()}>{multitenancyPhone()}</a>.</div>
        )}
        {asa_status === asaStatuses.DECLINED_BY_CLIENT && (
          <div>{t("You have declined the agreement. Please select a different payment plan and complete your agreement using the payment options available on your account. If you need assistance, contact us at ")} <a href={multitenancyTelUrl()}>{multitenancyPhone()}</a>.</div>
        )}
        {asa_status === asaStatuses.ATTEMPTS_LIMIT_REACHED && (
          <div>{t("We are no longer able to generate a stipulation agreement for your account. To resolve this matter, contact us at ")} <a href={multitenancyTelUrl()}>{multitenancyPhone()}</a>.</div>
        )}
        {errorMessage && (
          <div className="inner-text">
            <div>{t("Last payment was unsuccessful. Please update your payment method or contact us at")} {multitenancyPhone()}.</div>
            <div className="space">{errorMessage}</div>
          </div>
        )}
        {alert_notification && (
          <div>{alert_notification}</div>
        )}
      </div>
    </div>
  )
}