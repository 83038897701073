import React, { useState } from "react";
import moment from "moment";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { InfoIcon } from "scss/multitenantIcons/multitenantIcons";

import { ReceiptModal } from "../../../components/ReceiptModal";
import { formattedMoney } from "../../../lib/helpers/formatters";
import { useModal } from "../../../components/DebtCustomCard/hooks/useModal";

import { FileIcon } from "scss/multitenantIcons/fileIcon";
import { 
  NACHAButtonName, 
  paymentProcessingDate 
} from "components/DebtCustomCard/helpers";
import { 
  NACHAWebMultiModalData, 
  NACHAWebSingleModalData,
  NACHAPhoneMultiModalData,
  NACHAPhoneSingleModalData,

  OLD_NACHAWebMultiModalData,
  OLD_NACHAWebSingleModalData,
  OLD_NACHAPhoneMultiModalData,
  OLD_NACHAPhoneSingleModalData
} from "lib/common/NACHAModalData";

import { InfoTooltip } from "components/InfoTooltip";

import { useTranslation } from 'react-i18next';

import "./style.scss";

export const PaymentHistoryTable = ({data, showTable, debt}) => {

  const { t } = useTranslation();

  const { id } = useParams();
  const alert = useAlert();

  const {
    modalData,
    NACHAModalData,
    selectedHistoryType,
    setSelectedHistoryType,
    modalDate,
    selectedPayer,
    showModal,
    setShowModal,
    handleGetReceipt,
    handleShowNACHAModal
  } = useModal(id, debt, alert);

  const HTMLTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
    },
  }))(Tooltip);

  const [open, setOpen] = useState(null);

  const handleTooltipClose = () => {
    setOpen(null);
  };

  const handleTooltipOpen = (i) => {
    setOpen(i);
  };

  const showPaymentType = (type) => {
    if (type === "other") {
      return "Mail/Wire";
    } else if (type === "paypal") {
      return "PayPal";
    }
    return type;
  }

  const renderPlanType = (item) => {

    if (item.is_open_ended) {
      return "Open";
    }

    if (item.type === 'otp') {
      return "One Time";
    }

    if (item.type === "payment_plan" && !item.is_open_ended) {
      return "SIF/PIF";
    }
  }

  return (
    <div className={`paymentHistory-table-container ${showTable}`}>
      <table className={`paymentHistory-table ${showTable}`}>
        <thead>
          <tr>
            <th>{t("Date")}</th>
            <th>{t("Amount")}</th>
            <th>{t("Balance")}</th>
            {showTable === "history" && (
              <> 
                {<th>{t("Payment Method")}</th>}
                {data && <th>{t("Receipt")}</th>}
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, i) => {
            return (
            <tr key={i} className={item.success === false ? "failed" : "success"}>
              <td>{moment.utc(item.payment_date).format("ll")}</td>
              <td>$ {formattedMoney(item.amount)}</td>
              <td>$ {formattedMoney(item.remains)}</td>
              {showTable === "history" && (
                <td className="payment-method">                      
                  {t(showPaymentType(item.payment_method))}
                </td>
              )}
              {item.success && (
                <td className="receipt">
                  <div
                    onClick={() =>
                      handleGetReceipt(
                        item.amount,
                        item.payment_date,
                        item.type,
                        item.is_open_ended
                      )
                    }
                    type="button"
                    className="receipt-btn"
                  >
                    <FileIcon />
                    <span>{t("See Receipt")}</span>
                  </div>
                </td>
              )}
              {item.success === false && (
                <td className="failed-info-tooltip">
                  <InfoTooltip
                    title={item.status.split(/(?=[A-Z])/).join(" ")}
                  />
                </td>
              )}
              {showTable === "history" && (
                <td className="info">
                  <div className="info-icon">
                    <HTMLTooltip
                      id="html-tooltip"
                      open={open === i}
                      title={
                        <>
                          <div className="header">
                            <span>Details</span>
                            <button className="close-tooltip-btn" onClick={() => handleTooltipClose(i)}>
                              <Close />
                            </button>
                          </div>
                          <div className="main-block">
                            <div className="block-container">
                              <span className="label">{t("Plan Type")}</span>
                              <span className="value">{renderPlanType(item)}</span>
                            </div>
                            <div className="block-container">
                              <span className="label">{t("Processing Date")}</span>
                              <span className="value">{paymentProcessingDate(item.payment_date,item.payment_method)}</span>
                            </div>
                            {item.payment_method === "ach" && (
                              <div className="block-container">
                                <span className="label">ACH Auth</span>
                                <span className="value">
                                  <button
                                    onClick={() => handleShowNACHAModal(item)}
                                    type="button"
                                    className="button button-transparent link-btn"
                                  >
                                    {NACHAButtonName(item)}
                                  </button>
                                </span>
                              </div>
                            )}
                          </div>
                        </>
                      }
                      placement="left-start"
                    >
                      <div onClick={() => handleTooltipOpen(i)}>
                        <InfoIcon /> 
                      </div>
                    </HTMLTooltip>
                  </div>
                </td>
              )}
            </tr>
          )})}
        </tbody>
      </table>
      {showModal && modalData && (
        <ReceiptModal
          data={modalData}
          setShowModal={setShowModal}
          modalDate={modalDate}
        />
      )}
      {/* NEW NACHA FLOW */}
      {selectedPayer === "consumer" &&
      selectedHistoryType &&
      selectedHistoryType === "Regular" && NACHAModalData.number_of_payments > 1 && NACHAModalData.end_date !== null ? (
        <ReceiptModal
          data={
            NACHAWebMultiModalData(
              NACHAModalData.payment_frequency,
              NACHAModalData.type,
              NACHAModalData.bank_name,
              NACHAModalData.name_on_check,
              NACHAModalData.routing_number,
              NACHAModalData.account_number,
              false,
              NACHAModalData.start_date,
              NACHAModalData.end_date,
              NACHAModalData.start_amount,
              NACHAModalData.end_amount,
              NACHAModalData.agreement_timestamp,
            )
          }
          setShowModal={setSelectedHistoryType}
          modalDate={modalDate}
        />
      ) : null}
      {selectedPayer === "consumer" &&
      selectedHistoryType &&
      (selectedHistoryType === "Extra" ||
        selectedHistoryType === "One Time" || (selectedHistoryType === "Regular" && NACHAModalData.number_of_payments === 1)) && NACHAModalData.end_date !== null ? (
        <ReceiptModal
          data={
            NACHAWebSingleModalData(
              NACHAModalData.type,
              NACHAModalData.bank_name,
              NACHAModalData.name_on_check,
              NACHAModalData.routing_number,
              NACHAModalData.account_number,
              NACHAModalData.payment_amount,
              NACHAModalData.start_date,
              NACHAModalData.agreement_timestamp,
            )
        }
          setShowModal={setSelectedHistoryType}
          modalDate={modalDate}
        />
      ) : null}
      {selectedPayer === "admin/agent" &&
      selectedHistoryType &&
      selectedHistoryType === "Regular" && NACHAModalData.number_of_payments > 1 && NACHAModalData.end_date !== null ? (
        <ReceiptModal
          data={
            NACHAPhoneMultiModalData(
              NACHAModalData.start_date,
              NACHAModalData.end_date,
              NACHAModalData.name_on_check,
              NACHAModalData.type,
              NACHAModalData.account_number,
              NACHAModalData.is_change_payment_method,
              NACHAModalData.consumer_state,
              NACHAModalData.number_of_payments,
              NACHAModalData.payment_frequency,
              NACHAModalData.start_amount,
              NACHAModalData.end_amount,
              NACHAModalData.agreement_timestamp
            )
        }
          setShowModal={setSelectedHistoryType}
          modalDate={modalDate}
        />
      ) : null}
      {selectedPayer === "admin/agent" &&
      selectedHistoryType &&
      (selectedHistoryType === "Extra" ||
        selectedHistoryType === "One Time" || (selectedHistoryType === "Regular" && NACHAModalData.number_of_payments === 1)) && NACHAModalData.end_date !== null ? (
        <ReceiptModal
          data={
            NACHAPhoneSingleModalData(
              NACHAModalData.name_on_check,
              NACHAModalData.type,
              NACHAModalData.account_number,
              NACHAModalData.per_month,
              null,
              NACHAModalData.start_date,
              NACHAModalData.agreement_timestamp,
            )
          }
          setShowModal={setSelectedHistoryType}
          modalDate={modalDate}
        />
      ) : null}
      {/* END NEW NACHA FLOW */}
      {/* OLD NACHA FLOW */}
      {selectedPayer === "consumer" &&
      selectedHistoryType &&
      selectedHistoryType === "Regular" && NACHAModalData.end_date === null ? (
        <ReceiptModal
          data={
            OLD_NACHAWebMultiModalData(
              NACHAModalData.payment_frequency,
              NACHAModalData.per_month,
              NACHAModalData.start_date,
              NACHAModalData.type,
              NACHAModalData.bank_name,
              NACHAModalData.name_on_check,
              NACHAModalData.routing_number,
              NACHAModalData.account_number
            )
          }
          setShowModal={setSelectedHistoryType}
          modalDate={modalDate}
        />
      ) : null}
      {selectedPayer === "consumer" &&
      selectedHistoryType &&
      (selectedHistoryType === "Extra" ||
        selectedHistoryType === "One Time") && NACHAModalData.end_date === null ? (
        <ReceiptModal
          data={
            OLD_NACHAWebSingleModalData(
              NACHAModalData.type,
              NACHAModalData.bank_name,
              NACHAModalData.name_on_check,
              NACHAModalData.routing_number,
              NACHAModalData.account_number,
              NACHAModalData.payment_amount
            )
          }
          setShowModal={setSelectedHistoryType}
          modalDate={modalDate}
        />
      ) : null}
      {selectedPayer === "admin/agent" &&
      selectedHistoryType &&
      selectedHistoryType === "Regular" && NACHAModalData.end_date === null ? (
        <ReceiptModal
          data={
            OLD_NACHAPhoneMultiModalData(
              NACHAModalData.start_date,
              NACHAModalData.per_month,
              NACHAModalData.name_on_check,
              NACHAModalData.type,
              NACHAModalData.account_number
            )
          }
          setShowModal={setSelectedHistoryType}
          modalDate={modalDate}
        />
      ) : null}
      {selectedPayer === "admin/agent" &&
      selectedHistoryType &&
      (selectedHistoryType === "Extra" ||
        selectedHistoryType === "One Time") && NACHAModalData.end_date === null ? (
        <ReceiptModal
          data={
            OLD_NACHAPhoneSingleModalData(
              NACHAModalData.name_on_check,
              NACHAModalData.type,
              NACHAModalData.account_number,
              NACHAModalData.per_month
            )
          }
          setShowModal={setSelectedHistoryType}
          modalDate={modalDate}
        />
      ) : null}
      {/* END OLD NACHA FLOW */}
    </div>
  )
}